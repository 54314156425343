<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="text--accent-4">
          <v-list-item @click="$router.push({ name: 'Home' })">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="$auth.isAuthenticated"
            @click="$router.push({ name: 'Profile' })"
          >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar elevation="4">
      <v-app-bar-nav-icon @click="drawer = true">
        <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon>
        <v-img @click="$router.push({ name: 'Home' })" src="@/assets/logoK.svg"></v-img>
      </v-app-bar-nav-icon>
      
      <span @click="$router.push({ name: 'Home' })" class="text-h4 font-weight-thin color-black nameTopBar"
      >
        otty</span
      >

      <v-spacer></v-spacer>
      <v-btn elevation="0" @click="$router.push({ name: 'Home' })">Home</v-btn>
      <v-btn elevation="0" v-if="!$auth.isAuthenticated" @click="signin()"
        >Log in</v-btn
      >
      <v-btn elevation="0" v-if="$auth.isAuthenticated" @click="$auth.logout()"
        >Log out</v-btn
      >

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>
      <TheHomeMenu></TheHomeMenu>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import TheHomeMenu from "@/components/TheHomeMenu";
export default {
  name: "App",
  components: {
    TheHomeMenu
  },

  data: () => ({
    drawer: false,
    group: null
  }),
  methods: {
    signin() {
      this.$auth.loginWithRedirect();
    }
  },
  mounted() {
    document.title = "Kotty - Get Away";
  }
};
</script>
<style scoped>
.nameTopBar:hover {
  cursor: pointer;
}
</style>
