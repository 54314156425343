<template>
<div>
  <TheHomeBanner />
  <div class="mt-n10">
    <TheHomeCards />
  </div>
  
  <div style="height:500px"></div>
</div>
  
</template>

<script>
  import TheHomeBanner from '../components/TheHomeBanner'
  import TheHomeCards from '../components/TheHomeCards'

  export default {
    name: 'Home',
    title:"Kotty",

    components: {
      TheHomeBanner,
      TheHomeCards
    },
  }
</script>
