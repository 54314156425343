import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'


// Import the Auth0 configuration
import { domain, clientId } from "../auth_config.json";

// Import the plugin here
import { Auth0Plugin } from "./auth";

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

import firebase from 'firebase';
require('firebase/firestore');

/* Paste your firebase configuration below */
const config = {
  apiKey: "AIzaSyCA9wVrQlqwGPlI2isbYvOf_YeDTQnSQ_s",
  authDomain: "kotty-d1ff7.firebaseapp.com",
  projectId: "kotty-d1ff7",
  storageBucket: "kotty-d1ff7.appspot.com",
  messagingSenderId: "40375113206",
  appId: "1:40375113206:web:93099525b7768e2350e3d7",
  measurementId: "G-3QY9KHCQ3N"
};

/* Initialize firebase with your configuration */
firebase.initializeApp(config);

/* Bind firebase to your Vue instance */
Vue.prototype.$firebase = firebase;

Vue.config.productionTip = false;

Vue.config.productionTip = false
import Vuetify from "vuetify"
import vuetify from './plugins/vuetify'
Vue.use(Vuetify);
new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
