<template>
  <v-container>
    <v-card>
      <v-card-title> Get Away </v-card-title>
      <v-card-subtitle>Find your home away from home</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col align="center"
            ><v-card class="pa-1 homeCard"
              ><v-card-title> Ski Chalet </v-card-title>
              <router-link to="/">
              <v-img
                height="427"
                src="@/assets/skichalet.jpg"
              ></v-img> </router-link></v-card
          ></v-col>
          <v-col align="center"
            ><v-card class="pa-1 homeCard"
              ><v-card-title> Lakeside </v-card-title>
              <router-link to="/">
                <v-img
                  height="427"
                  src="@/assets/lakehouse.jpg"
                ></v-img>
              </router-link> </v-card
          ></v-col>

          <v-col align="center"
            ><v-card class="pa-1 homeCard"
              ><v-card-title>For the whole family </v-card-title>
              <router-link to="/">
              <v-img
                height="427"
                src="@/assets/doghouse.jpg"
              ></v-img> </router-link></v-card
          ></v-col>
        </v-row>
        <!-- <v-row>
          <v-col>
            {{testData}}
          </v-col>
        </v-row> -->
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      testData: []
    }
    
  },
   mounted() {
    const db = this.$firebase.firestore();
    db
      .collection('testData')
      .get()
      .then(snap => {
        const testCollection = [];
        snap.forEach(doc => {
          testCollection.push({ [doc.id]: doc.data() });
        });
        this.testData = testCollection;
      });
  },
}
</script>
<style scoped>
.homeCard {
  height: 500px;
  width: 350px;
}
</style>
